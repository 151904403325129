<template>
  <div class="aside-wrapper">
    <el-menu
      :default-active="$route.path"
      router
      active-text-color="#178326"
      class="el-menu-vertical-demo"
      @select="selectmet"
    >
      <el-menu-item
        :index="item.path"
        v-for="(item, index) in listdata"
        :key="index"
        v-show="
          (actives == 0 && index != 1) ||
          (actives == 1 && index != 0)"
      >
        <div class="menus">
          <div style="width: 12px">
            <img
              :src="item.leftImg"
              alt=""
              v-show="(($route.path === item.path || $route.query.ind == item.ind) && !mentactve)  || (item.ind == 11 && mentactve)"
              style="height: 0.9rem"
            />
          </div>
          <div class="imgsa">
            <img
              style="margin: 0 0.3rem"
              :src="
                (($route.path === item.path || $route.query.ind == item.ind) && !mentactve) || (item.ind == 11 && mentactve)
                  ? item.activeIcon
                  : item.icon
              "
              alt=""
            />
          </div>
          <div slot="title" class="el-menu-item-title">
            {{ item.name }}
            <div
              class="spanmess_mes"
              v-if="item.path == '/nav/user/workorder' && statusMg == 0"
            ></div>
          </div>
          <img
          v-if="!mentactve && item.ind == 11"
            src="@/assets/imgs/devlis/xiangyoujiantou.png"
            style="width: 0.3rem; margin-left: 0.5rem"
            alt=""
          />
          <img
          v-if="mentactve && item.ind == 11"
            src="@/assets/imgs/devlis/xiangxiajiantou.png"
            style="width: 0.3rem; margin-left: 0.5rem"
            alt=""
          />
        </div>
        <div
          class="devisa"
          v-if="index == 0 || index == 1"
          style="
            display: flex;
            justify-content: flex-start;
            padding-left: 0.5rem;"
        >
          <i
            class="el-icon-s-operation"
            @click.stop="activemensad(0)"
            :style="actives == 0 ? 'color:rgb(23, 131, 38);' : ''"
          ></i>
          <i
            class="el-icon-menu"
            @click.stop="activemensad(1)"
            :style="actives == 1 ? 'color:rgb(23, 131, 38);' : ''"
          ></i>
        </div>
        <div v-if="index==0 && actives == 0" v-show="$route.path === item.path|| $route.query.ind == item.ind">
					<div class="devisa" v-for="(ites,ind) in tals" :key='ind' :class="ind===filgs?'actis':''" @click.stop="rulet(ind,ites)">
						<img v-if="ites.off" src="@/assets/imgs/devlis/offis.png" />
						<img v-if="ites.live" src="@/assets/imgs/devlis/live.png" />
						<img v-if="ites.onfile" src="@/assets/imgs/devlis/onopen.png" />
						<img v-if="ites.recs" src="@/assets/imgs/devlis/recs.png" />
						<el-tooltip class="item" effect="dark" :content="ites.tex" placement="right">
							<div style="position: relative;"><div class="texads">{{ites.tex}}</div> <el-button v-if="ind===filgs && ites.off" type="text" class="btsad" @click="removedives(ind,ites)">{{$t("lang.Unbundling")}}</el-button> </div>
						</el-tooltip>
					</div>
				</div>
        <div
          v-if="item.ind == 11 && mentactve"
        >
          <div
            class="devisa"
            v-for="(ites, ind) in item.chouen"
            :key="ind"
            :class="ind === filgs ? 'actis' : ''"
            @click.stop="addtopads(ind, ites)"
            v-show="ites.istypes"
          >
            <img v-if="ites.off" src="@/assets/imgs/devlis/offis.png" />
            <img v-if="ites.live" src="@/assets/imgs/devlis/live.png" />
            <img v-if="ites.onfile" src="@/assets/imgs/devlis/onopen.png" />
            <img v-if="ites.recs" src="@/assets/imgs/devlis/recs.png" />
            <div :style="item.chouen.length > 0 ?'width:2.4rem; text-align: center;':''">{{ ites.name }}</div>
          </div>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import * as admin from "@/api/roleList.js";
import bus from "@/utils/bus.js";
import { getEqList } from "@/api/apis";
import * as contract from "@/api/contract.js";
export default {
  data() {
    return {
      mentactve:false,
      istypes: false,
      statusMg: 1,
      timer: "",
      filgs: 0,
      tals: [],
      taltimer: null,
      resdata: "",
      actives: 0,
    };
  },
  created() {
    this.listads();
    this.getReadStatus();
    if (sessionStorage.getItem("actives")) {
      this.actives = sessionStorage.getItem("actives");
    }

    let srs = JSON.parse(sessionStorage.getItem("devfilg"));
    if (srs) {
      this.filgs = srs;
    }
    //this.getdevlist()
    console.log(this.$route);
    let uslsa = this.$route.fullPath;
    // debugger
    let sk = uslsa.split("?");
    if (uslsa === "/nav/user/equipment" || sk[0] === "/nav/user/equipment") {
      this.getdevlist();
      this.taltimer = setInterval(() => {
        this.getdevlist();
      }, 1000);
    }
    //  bus.$on('langchange', () => {
    // console.log('kkk')
    //    let lgArr = [this.$t('lang.equipment'),this.$t('lang.media'),this.$t('lang.Workorder'),this.$t('lang.journal'),this.$t('lang.myword')]
    //    this.listdata.forEach((item,index) => {
    //      item.name = lgArr[index]
    //    })
    //  })
  },
  computed: {
    listdata() {
      return [
        {
          path: "/nav/user/equipment",
          ind: 10,
          name: this.$t("lang.equipment"),
          leftImg: require("@/assets/imgs/pic.png"),
          icon: require("@/assets/imgs/devlis/shebei2.png"),
          activeIcon: require("@/assets/imgs/devlis/shebei.png"),
          chouen:[],
        },
        {
          path: "/nav/user/equipmentList",
          ind: 10,
          name: this.$t("lang.equipment"),
          leftImg: require("@/assets/imgs/pic.png"),
          icon: require("@/assets/imgs/devlis/shebei2.png"),
          activeIcon: require("@/assets/imgs/devlis/shebei.png"),
          chouen:[],
        },
        {
          path: "/nav/user/media",
          ind: 10,
          name: this.$t("lang.media"),
          leftImg: require("@/assets/imgs/pic.png"),
          icon: require("@/assets/imgs/devlis/meiti.png"),
          activeIcon: require("@/assets/imgs/devlis/meiti2.png"),
          chouen:[],
        },
        {
          path: "/nav/user/workorder",
          ind: 10,
          name: this.$t("lang.Workorder"),
          leftImg: require("@/assets/imgs/pic.png"),
          icon: require("@/assets/imgs/devlis/gongdan.png"),
          activeIcon: require("@/assets/imgs/devlis/gongdan2.png"),
          chouen:[],
        },
        {
          path: "/nav/user/journal",
          ind: 10,
          name: this.$t("lang.journal"),
          leftImg: require("@/assets/imgs/pic.png"),
          icon: require("@/assets/imgs/devlis/rizhi.png"),
          activeIcon: require("@/assets/imgs/devlis/rizhi2.png"),
          chouen:[],
        },
        {
          path: "",
          name: this.$t("lang.myword"),
          ind: 11,
          leftImg: require("@/assets/imgs/pic.png"),
          icon: require("@/assets/imgs/devlis/wode.png"),
          activeIcon: require("@/assets/imgs/devlis/wode2.png"),
          chouen: [
            {
              path: "/nav/user/personal?ind=0",
              ind: 0,
              name: this.$t("lang.PackageCenter"),
              leftImg: require("@/assets/imgs/pic.png"),
              icon: require("@/assets/imgs/devlis/0011.png"),
              activeIcon: require("@/assets/imgs/devlis/00111.png"),
              istypes:this.istypes,
            },
            {
              path: "/nav/user/personal?ind=1",
              ind: 1,
              name: this.$t("lang.Ordercenters"),
              leftImg: require("@/assets/imgs/pic.png"),
              icon: require("@/assets/imgs/devlis/0011.png"),
              activeIcon: require("@/assets/imgs/devlis/00111.png"),
              istypes:true,
            },
            {
              path: "/nav/user/personal?ind=2",
              ind: 2,
              name: this.$t("lang.billingcenters"),
              leftImg: require("@/assets/imgs/pic.png"),
              icon: require("@/assets/imgs/devlis/0033.png"),
              activeIcon: require("@/assets/imgs/devlis/00331.png"),
              istypes:true,
            },
            {
              path: "/nav/user/personal?ind=3",
              ind: 3,
              name: this.$t("lang.Streamingscheme"),
              leftImg: require("@/assets/imgs/pic.png"),
              icon: require("@/assets/imgs/devlis/0055.png"),
              activeIcon: require("@/assets/imgs/devlis/00551.png"),
              istypes:true,
            },
            {
              path: "/nav/user/personal?ind=4",
              ind: 4,
              name: this.$t("lang.accountsettings"),
              leftImg: require("@/assets/imgs/pic.png"),
              icon: require("@/assets/imgs/devlis/0077.png"),
              activeIcon: require("@/assets/imgs/devlis/00771.png"),
              istypes:true,
            },
          ],
        },
      ];
    },
  },
  watch: {
    $route(to, from) {
      console.log(to);
      let sk = to.fullPath.split("?");
      if (to.fullPath !== "/nav/user/equipment") {
        if (this.taltimer) {
          clearInterval(this.taltimer);
        }
      }
      if (
        to.fullPath === "/nav/user/equipment" ||
        sk[0] === "/nav/user/equipment"
      ) {
        this.getdevlist();
        this.taltimer = setInterval(() => {
          this.getdevlist();
        }, 1000);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.taltimer);
    sessionStorage.removeItem("devfilg");
    sessionStorage.removeItem("devlist");
  },
  mounted() {
   
    this.timer = setInterval(() => {
      this.getReadStatus();
    }, 6000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
    });
  },
  methods: {
    addtopads(index,item){
      this.$router.push(item.path);
      this.filgs = index;
      this.mentactve = true
    },
    selectmet(e){
      if(!e){
        this.mentactve = true
      }else{
        this.mentactve = false
      }
      // if(e.query.ind != 0 && e.query.ind != 1 && e.query.ind != 2 && e.query.ind != 3 && e.query.ind != 4) {
      //   this.mentactve = false
      // }
      
    },
    listads() {
      contract.mediagetOpenMeal({ type: "" }).then((res) => {
        this.istypes = res.data==1?true:false;
      });
    },
    activemensad(index) {
      this.actives = index;
      sessionStorage.setItem("actives", index);
      if (index == 1) {
        this.$router.push({
          path: "/nav/user/equipmentList",
        });
      } else {
        this.$router.push("/nav/user/equipment");
      }
    },
    async getReadStatus() {
      let res = await admin.getReadStatus();
      // console.log(res.data)
      this.statusMg = res.data;
    },
    //获取设备列表
    async getdevlist() {
      let tals_a = [];
      let params = {
        email: JSON.parse(sessionStorage.getItem("user")).email,
        type: "0",
      };
      let res = await getEqList(params);
      //console.log(res)
      let equipmentList = {};
      if (res.data.length > 0) {
        res.data.forEach((item, index) => {
          tals_a.push({
            tex: item.name,
            imd: item.mid,
            off: false,
            live: false,
            recs: false,
            onfile: false,
          });
          item.adlist = item.steamAddr;
          if (item.steamAddr) {
            let sturl = JSON.parse(item.steamAddr);
            let videodes = [];
            let stulr = sturl.pullStream;
            stulr.forEach((items, indexs) => {
              items.isfor = false;
              if (items.type === 3) {
                videodes.push(items);
                stulr.splice(indexs, 1);
              }
            });
            if (
              item.deviceConfigDto.pullStream &&
              item.deviceConfigDto.pullStream.length > 0
            ) {
              item.deviceConfigDto.pullStream.forEach((itea, inids) => {
                if (itea.enable == 1 && itea.type !== 3) {
                  stulr[inids].isfor = true;
                }
              });
            }
            item.steamAddr = stulr;
            item.videos = videodes;
          }

          if (item.deviceRealtimeInfoDto.pushstreamout == null) {
            item.deviceRealtimeInfoDto.pushstreamout = [];
          }
          if (item.status == 1) {
            tals_a[index].onfile = true;
          } else {
            tals_a[index].off = true;
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.workModeStatus
          ) {
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode == 0 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
            ) {
              tals_a[index].live = true;
              tals_a[index].onfile = false;
            }
            if (
              item.deviceRealtimeInfoDto.workModeStatus.workMode == 1 &&
              item.deviceRealtimeInfoDto.workModeStatus.workStatus == 1
            ) {
              tals_a[index].recs = true;
              tals_a[index].onfile = false;
            }
          }

          if (item.deviceConfigDto && item.deviceConfigDto.pullStream) {
            item.deviceConfigDto.pullStream.forEach((ites) => {
              if (ites.type === 3) {
                res.data[index].videos = [ites];
              }
            });
          }
          if (item.linkInfo !== null && item.linkInfo.length !== 0) {
            item.linkInfo.forEach((obj) => {
              if (obj.recvRate) {
                obj.recvRate = obj.recvRate.split(",");
              }
              if (obj.signal > 0) {
                obj.signal = Math.round(obj.signal / 20);
              }
            });
          }
          if (
            item.deviceRealtimeInfoDto &&
            item.deviceRealtimeInfoDto.recvRate != null
          ) {
            let sk = item.deviceRealtimeInfoDto.recvRate.split(",");
            let skd = sk[sk.length - 1];
            item.deviceRealtimeInfoDto.recvRate = (
              (Number(skd) * 8) /
              1024
            ).toFixed(2);
            let saw = [];
            sk.forEach((itesa) => {
              let skr = ((Number(itesa) * 8) / 1024).toFixed(2);
              saw.push(skr);
            });
            item.Linechart = "";
            item.Linechart = saw;
          }
        });
        // debugger
        sessionStorage.setItem("allslist", JSON.stringify(res.data));
        let skrs = JSON.parse(sessionStorage.getItem("devfilg"));
        if (skrs) {
          if (res.data[skrs]) {
            equipmentList = JSON.stringify(res.data[skrs]);
          } else {
            equipmentList = JSON.stringify(res.data[res.data.length - 1]);
          }
        } else {
          equipmentList = JSON.stringify(res.data[0]);
        }
        this.resdata = JSON.parse(equipmentList);
        sessionStorage.setItem("devlist", equipmentList);
      } else {
        sessionStorage.setItem("devlist", null);
        sessionStorage.setItem("allslist", null);
      }
      this.tals = tals_a;
      //console.log(this.tals)
    },
    //选择不同的设备
    rulet(ind, item) {
      this.filgs = ind;
      sessionStorage.setItem("devfilg", ind);
      this.$router.push({
        path: "/nav/user/equipment",
        query: {
          mid: item.imd,
        },
      });
    },
    // 解绑
    async removedives(ind,item){
      let mid  = item.imd;
      let res = await admin.devicedelete(mid);
    },
  },
};
</script>

<style lang="less" scoped>
.spanmess_mes {
  display: inline-block;
  width: 0.1rem;
  height: 0.1rem;
  background: red;
  position: absolute;
  right: -0.18rem;
  top: 2px;
  border-radius: 50%;
}
.aside-wrapper {
  width: 100%;
}
.el-menu {
  width: 100%;
  padding: 0 0.15rem;
  box-sizing: border-box;
  // padding-top: 10px;
  background-color: #181c1c;
  background-image: url("../../../src/assets/imgs/bglist.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  .el-menu-item {
    min-height: 0.9rem;
    line-height: 0;
    display: table;
    width: 100%;
    background-color: #181c1c;
    margin-top: 0.1rem;
    border-radius: 4px;
    color: #b7bac5;
    padding: 0 !important;
    .menus {
      height: 0.9rem;
      display: flex;
      align-items: center;
      .imgsa {
        img {
          width: 0.42rem;
        }
      }
    }
    .el-menu-item-title {
      position: relative;
      font-size: 0.2rem;
    }
  }
}
a {
  text-decoration: none;
}
.list {
  height: 1.26rem;
  margin-bottom: 0.15rem;
  background: rgba(024, 028, 028, 0.99);
  /*     opacity: 0.29; */
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;
  p {
    margin-left: 1.33rem;
    font-size: 0.28rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b7bac5;
    line-height: 0.35rem;
  }
  img {
    width: 0.55rem;
    height: 0.49rem;
    position: absolute;
    left: 0.47rem;
    top: 0.39rem;
    margin-right: 0.31rem;
  }
  img.strip {
    width: 0.16rem;
    height: 1.26rem;
    position: absolute;
    background: linear-gradient(180deg, #0c410a, #167524);
    border-radius: 4px 0px 0px 4px;
    top: 0px;
    left: 0px;
    display: none;
  }
  img.stripactive {
    display: block;
  }
}
.devisa {
  width: 100%;
  //padding-left: .42rem;
  height: 0.5rem;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  //line-height: .5rem;
  text-align: left;
  background-color: #181c1c;
  color: #b7bac5;
  font-size: 0.2rem;
  img {
    margin-right: 0.25rem;
  }
  div {
    width: 1.4rem;
    // height: 100%;
    line-height: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0.15rem;
    text-overflow: ellipsis;
  }
}
.actis {
  background-color: #383b3b;
}
.btsad{
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
}
.texads{
  width: 90px !important;
  display: inline-block;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
</style>
