<template>
  <div style="width:100%">
    <el-container>
      <el-aside>
        <UserList style="margin-bottom: 20px;" />
				<div class="monyls">
					<img src="@/assets/imgs/devlis/yue.png" style="margin-right: 9px;" />
					<span>{{$t("lang.balances")}}：</span>
					<span>￥{{monys}}</span>
					<div @click="mony">{{$t('lang.Balancerecharge')}}</div>
				</div>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import UserList from "@/components/userlist/UserList";
import * as nav from "@/api/apis";
export default {
  components: {
    UserList,
  },
	data(){
		return{
			monys:'',
			time:null
		}
	},
	mounted() {
		this.getuser()
		this.time=setInterval(()=>{
			this.monys = sessionStorage.getItem("balance")
		},2000)
	},
	methods:{
		//获取用户信息
		async getuser(){
			if(JSON.parse(sessionStorage.getItem("user"))){
				let email=JSON.parse(sessionStorage.getItem("user")).email
				let res=await nav.userId({email:email})
				//console.log(res)
				if(res){
					let data=res.data
					let moneysa=data.money
					this.monys=data.balance?data.balance:'0'
					data.password=''
					sessionStorage.setItem("user", JSON.stringify(data));
					sessionStorage.setItem("balance", this.monys);
					sessionStorage.setItem("money", moneysa);
				}
			}
		},
		//跳转个人中心充值
		mony(){
			this.$router.push({
				path:'/nav/personal',
				query:{ind:5}
			})
		}
	},
	beforeDestroy() {
		if(this.time){
			clearInterval(this.time)
		}
	},
};
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  height: 100%;
  .el-aside {
    width: 3rem !important;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	
    background: url("../../assets/imgs/bglist.png");
  }
  .el-main {
    width: calc(100% - 3rem);
		box-sizing: border-box;
    background-color: #5c5c5f;
    padding: .15rem;
  }
}
.monyls{
	padding: .15rem 0;
	font-size: .14rem;
	display: flex;
	flex-flow: row;
	justify-content: flex-end;
	align-items: center;
	background-color: rgba(37, 41, 44, .29);
	color: #B7BAC5;
	img{
		width: .23rem;
		height: .24rem;
	}
	div{
		cursor: pointer;
		width: .8rem;
		height: .28rem;
		border: 1px solid #307B37;
		color: #307B37;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		border-radius: 2px;
		margin-left: .2rem;
		margin-right: .2rem;
	}
}
</style>
